import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Home.css";
import Python from "../../Icons/Python.png"; // Python ikonunu ekliyoruz
import Replit from "../../Icons/replit.png"; // Replit ikonunu ekliyoruz

const Home = () => {
  const { userId } = useParams();
  const [completedWeeks, setCompletedWeeks] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [courseDetails, setCourseDetails] = useState({});
  const totalWeeks = 20; // Toplam hafta sayısı (örneğin)

  const fetchCompletedWeeks = useCallback(async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`https://ubicraft.org/api/progress/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    setCompletedWeeks(data.completedWeeks); // Ensure it's an array of week numbers
    const progress = (data.completedWeeks.length / totalWeeks) * 100;
    setProgressPercentage(progress);
  }, [userId, totalWeeks]);

  const fetchCourseDetails = useCallback(async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`https://ubicraft.org/api/student/${userId}/course-details`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    setCourseDetails(data);
  }, [userId]);

  useEffect(() => {
    fetchCompletedWeeks();
    fetchCourseDetails();
  }, [fetchCompletedWeeks, fetchCourseDetails]);

  const navigate = useNavigate();

  const handleProgressClick = () => {
    navigate(`/student/${userId}/notification`);
  };

  const handleNewProjectClick = () => {
    // Yeni proje oluşturma logic burada olabilir
  };

  return (
    <div className="home-container">
      <div className="home-track-card">
        <div className="home-track-header">
          <span className="home-track-title">SERTİFİKA İÇİN HAZIRLANIN</span>
          <h1>{courseDetails.course_name}</h1>
          <p className="home-track-description">{courseDetails.course_name} kursuna kayıt oldunuz</p>
        </div>
        <div className="home-track-info">
          <div className="home-progress-container">
            <div className="home-progress-bar-container">
              <div className="home-progress-bar" style={{ width: `${progressPercentage}%` }}>
                <span className="home-progress-percentage">{Math.round(progressPercentage)}%</span>
              </div>
            </div>
            <div className="home-progress-details">
              <span>{totalWeeks - completedWeeks.length} hafta kaldı</span>
              <button className="home-progress-button" onClick={handleProgressClick}>Kursa Devam Et</button>
            </div>
          </div>
        </div>
        <div className="home-course-footer">
          <div className="home-course-section home-review">
            <div className="home-section-header">
              <span className="home-section-icon">🔍</span>
              <span className="home-section-title">GÖZDEN GEÇİRİN</span>
            </div>
            <div className="home-section-body">
              <span className="home-section-count">{completedWeeks.length}</span>
              <span> Ders</span>
            </div>
          </div>
          <div className="home-course-section home-assess">
            <div className="home-section-header">
              <span className="home-section-icon">📝</span>
              <span className="home-section-title">SIRADAKİ DERS</span>
            </div>
            <div className="home-section-body">
              <span>{courseDetails.next_title}</span>
            </div>
          </div>
          <div className="home-course-section home-practice">
            <div className="home-section-header">
              <span className="home-section-icon">💪</span>
              <span className="home-section-title">ÖDEVLERİ TAMAMLAYIN</span>
            </div>
            <div className="home-section-body">
              <span>{courseDetails.practice_title}</span>
            </div>
          </div>
          <div className="home-course-section home-apply">
            <div className="home-section-header">
              <span className="home-section-icon">🚀</span>
              <span className="home-section-title">SIRADAKİ PROJE</span>
            </div>
            <div className="home-section-body">
              <span>{courseDetails.apply_title}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="replit-container">
        <div className="replit-card">
          <div className="replit-header">
            <img src={Replit} alt="Replit" className="replit-icon" />
            <div className="replit-content">
              <h2 className="replit-title">Bir gün değil, her gün öğrenin!</h2>
              <p className="replit-description">Her hafta yeni bir Replit projesi ile öğreniminize ara vermeden devam edin!</p>
            </div>
          </div>
          <button className="replit-button">Etkinliğe Git</button>
        </div>
        <div className="replit-card">
          <div className="replit-header">
            <img src={Replit} alt="Replit" className="replit-icon" />
            <div className="replit-content">
              <h2 className="replit-title">Kendi projenizi oluşturun!</h2>
              <p className="replit-description">Platformdaki etkinliklerle sınırlı kalmayın ve potansiyelinizi yeni bir proje ile açığa çıkartın.</p>
            </div>
          </div>
          <button className="replit-button" onClick={handleNewProjectClick}>Yeni Proje Oluştur</button>
        </div>
      </div>
    </div>
  );
};

export default Home;
