import React from "react";
import { Link, useParams } from "react-router-dom";
import "./Topics.css";

const Topics = () => {
  const { userId } = useParams();
  
  const topics = [
    { id: 1, name: 'Makine Öğrenmesi', description: 'Bu Makine Öğrenmesi\'nin açıklamasıdır.', url: `/dashboard/${userId}/topics/1` },
    { id: 2, name: 'Derin Öğrenme', description: 'Bu Derin Öğrenme\'nin açıklamasıdır.', url: `/dashboard/${userId}/topics/2` },
    { id: 3, name: 'Makine Öğrenimi ve Derin Öğrenme Farkı', description: 'Bu Makine Öğrenimi ve Derin Öğrenme Farkı\'nın açıklamasıdır.', url: `/dashboard/${userId}/topics/3` }
  ];

  return (
    <div className="topics-container">
      <div className="week-card">
        <div className="week-header">
          <h2>Konu Anlatımları</h2>
        </div>
        <div className="activities">
          {topics.map(topic => (
            <div key={topic.id} className="activity-card">
              <span>{topic.name}</span>
              <Link to={topic.url}>
                <button className="activity-toggle-button">Konu Anlatımına Git</button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Topics;
