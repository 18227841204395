import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Notification.css';
import TrackCard from './TrackCard';
import Participation from './Participation';  // Yeni eklenen
import Score from './Score';  // Yeni eklenen

const Notification = () => {
  const { userId } = useParams();
  const [lessons, setLessons] = useState([]);
  const [expandedWeek, setExpandedWeek] = useState(null);

  useEffect(() => {
    const fetchLessons = async () => {
  
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://ubicraft.org/api/student/${userId}/lessons`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();

  
          const lessonsByWeek = data.reduce((acc, lesson) => {
            const weekNumber = lesson.week_number;
  
            if (!acc[weekNumber]) {
              acc[weekNumber] = {
                week_number: weekNumber,
                lesson_name: lesson.lesson_name,
                details: lesson.details,
                slide: lesson.slide,
                is_accessible: lesson.is_accessible,
                activities: [],
              };
            }
  
            acc[weekNumber].activities.push({
              activity_id: lesson.activity_id,
              activity_name: lesson.activity_name,
              replit_url: lesson.replit_url,
            });
            return acc;
          }, {});
  
  
          setLessons(Object.values(lessonsByWeek));
  
          // State'e set edilen veriyi kontrol edelim
        } else {
          console.error('Dersler alınamadı:', response.statusText);
        }
      } catch (error) {
        console.error('Dersleri alırken hata oluştu:', error);
      }
    };
  
    fetchLessons();
  }, [userId]);
  
  const toggleWeek = (weekNumber) => {
    setExpandedWeek((prev) => (prev === weekNumber ? null : weekNumber));
  };

  const renderModule = (moduleLessons, moduleNumber) => (
    <div key={moduleNumber} className="module-border">
      <div className="module-header">
        <h2 className="module-title">Modül {moduleNumber}</h2>
      </div>
      {moduleLessons.map((lesson, index) => (
        <div
          key={index}
          className={`lesson-card ${lesson.is_accessible ? 'accessible' : 'locked'}`}
        >
          <div className="lesson-header" onClick={() => lesson.is_accessible && toggleWeek(lesson.week_number)}>
            <div className="week-number-circle">{lesson.week_number}</div>
            <div className="lesson-info">
              <h3 className='lesson-name'>{lesson.lesson_name}</h3>
              <p>{lesson.details}</p>
            </div>
            {/* Haftanın Kaynağı butonunu ve kilitli mesajını aynı satıra ekleme */}
            <div className="locked-section">
              {!lesson.is_accessible && (
                <p className="locked-message">Bu ders henüz erişilebilir değil.</p>
              )}
              {lesson.slide && (
                <a
                  className="common-button slide-button"
                  href={lesson.slide}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  Haftanın Kaynağı
                </a>
              )}
            </div>
            <div className={`arrow ${expandedWeek === lesson.week_number ? 'expanded' : ''}`}>
              <span>&#9660;</span>
            </div>
          </div>
          {expandedWeek === lesson.week_number && (
            <>
              <hr />
              <div
                className={`activities-container ${expandedWeek === lesson.week_number ? 'show' : ''}`}
                style={{ maxHeight: expandedWeek === lesson.week_number ? '1000px' : '0px' }}
              >
                {lesson.activities.map((activity, idx) => (
                  <div key={activity.activity_id} className="activity-item">
                    <div className="activity-number-circle">{idx + 1}</div>
                    <span>{activity.activity_name}</span>
                    <a className="common-button activity-link" href={activity.replit_url} target="_blank" rel="noopener noreferrer">
                      Etkinliğe Git
                    </a>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );

  const groupedLessons = lessons.reduce((acc, lesson, index) => {
    const moduleIndex = Math.floor(index / 5);
    if (!acc[moduleIndex]) {
      acc[moduleIndex] = [];
    }
    acc[moduleIndex].push(lesson);
    return acc;
  }, []);

  return (
    <div className="notification-container">
      <TrackCard />
      <div className="lessons-container">
        {groupedLessons.map((moduleLessons, moduleNumber) =>
          renderModule(moduleLessons, moduleNumber + 1)
        )}
      </div>
    </div>
  );
};

export default Notification;
