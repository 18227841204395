import React, { useEffect, useState } from "react";
import AttendanceCard from "../../customComponents/AttendanceCard"; // AttendanceCard komponentini doğru yoldan import ediyoruz
import SearchBar from "../../customComponents/SearchBar";

const Attendance = () => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch('https://ubicraft.org/api/students');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStudents(data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, []);

  return (
    <div>
      <SearchBar />
      <div className="flex flex-col gap-4 mx-6 mt-4">
        <div className="flex w-full rounded-xl h-12 bg-white mt-8 px-2 py-2">
          <div className="flex items-center justify-between px-2">
            <p className="font-poppins font-bold text-[#000000] text-l" style={{marginLeft:100}}>İSiM</p>
            <p className="font-poppins font-bold text-[#000000] text-l">KATILIM</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mx-6 mt-4">
        {students.map((student) => (
          <AttendanceCard
            key={student.id}
            Name={student.name}
            studentId={student.id}
          />
        ))}
      </div>
    </div>
  );
};

export default Attendance;
