import React, { useState, useEffect } from 'react';
import './StudentManagement.css';

const StudentManagement = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [classDetails, setClassDetails] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [newStudent, setNewStudent] = useState({
    name: '',
    password: '',
    role: '',
    email: '',
    phone_number: '',
    guardian_name: '',
    balance: '',
    course_name: '',
    class_id: '', // Sınıf seçimi
  });

  useEffect(() => {
    fetch('https://ubicraft.org/api/classes')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  useEffect(() => {
    if (selectedClassId) {
      fetch(`https://ubicraft.org/api/classes/${selectedClassId}`)
        .then(response => response.json())
        .then(data => setClassDetails(data))
        .catch(error => console.error('Error fetching class details:', error));
    }
  }, [selectedClassId]);

  const handleClassSelect = (e) => {
    setSelectedClassId(e.target.value);
  };

  const handleDeleteStudent = (studentId) => {
    fetch(`https://ubicraft.org/api/students/${studentId}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete student');
        }
        setClassDetails({
          ...classDetails,
          students: classDetails.students.filter(student => student.id !== studentId),
        });
      })
      .catch(error => console.error('Error deleting student:', error));
  };

  const handleClassDetailsChange = (e) => {
    const { name, value } = e.target;
    setClassDetails({ ...classDetails, class: { ...classDetails.class, [name]: value } });
  };

  const handleSaveClassDetails = () => {
    fetch(`https://ubicraft.org/api/classes/${classDetails.class.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(classDetails.class),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update class details');
        }
        return response.json();
      })
      .then(data => setClassDetails({ ...classDetails, class: data }))
      .catch(error => console.error('Error updating class details:', error));
  };

  const handleStudentChange = (e, student) => {
    const { name, value } = e.target;
    setSelectedStudent({ ...student, [name]: value });
  };

  const handleSaveStudentDetails = (studentId) => {
    fetch(`https://ubicraft.org/api/students/${studentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedStudent),
    })
      .then(response => response.json())
      .then(data => {
        setClassDetails({
          ...classDetails,
          students: classDetails.students.map(student => student.id === studentId ? data : student),
        });
        setSelectedStudent(null);
      })
      .catch(error => console.error('Error updating student details:', error));
  };

  const handleClassChangeForStudent = (studentId, newClassId) => {
    fetch(`https://ubicraft.org/api/students/${studentId}/class`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ class_id: newClassId }),
    })
      .then(response => response.json())
      .then(data => {
        setClassDetails({
          ...classDetails,
          students: classDetails.students.map(student =>
            student.id === studentId ? { ...student, class_id: newClassId } : student
          ),
        });
      })
      .catch(error => console.error('Error changing student class:', error));
  };

  const handleNewStudentChange = (e) => {
    const { name, value } = e.target;
    setNewStudent({ ...newStudent, [name]: value });
  };

  const handleAddNewStudent = () => {
    fetch('https://ubicraft.org/api/students', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newStudent),
    })
      .then(response => response.json())
      .then(data => {
        if (newStudent.class_id) {
          fetch(`https://ubicraft.org/api/students/${data.id}/class`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ class_id: newStudent.class_id }),
          })
            .then(() => {
              setClassDetails({
                ...classDetails,
                students: [...classDetails.students, data],
              });
            })
            .catch(error => console.error('Error assigning student to class:', error));
        }
        setNewStudent({
          name: '',
          password: '',
          role: '',
          email: '',
          phone_number: '',
          guardian_name: '',
          balance: '',
          course_name: '',
          class_id: '', // Seçilen sınıf
        });
      })
      .catch(error => console.error('Error adding new student:', error));
  };

  return (
    <div className="student-management">
      <h2>Class and Student Management</h2>

      <div className="class-selection">
        <label htmlFor="class-select">Select a class:</label>
        <select id="class-select" onChange={handleClassSelect}>
          <option value="">-- Select a class --</option>
          {classes.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.name}
            </option>
          ))}
        </select>
      </div>

      {classDetails && (
        <>
          <div className="class-details">
            <h3>Edit Class Details</h3>
            <label>Class Name:</label>
            <input
              type="text"
              name="name"
              value={classDetails.class.name || ''}
              onChange={handleClassDetailsChange}
            />
            <label>Start Date:</label>
            <input
              type="date"
              name="start_date"
              value={classDetails.class.start_date || ''}
              onChange={handleClassDetailsChange}
            />
            <label>Course ID:</label>
            <input
              type="number"
              name="course_id"
              value={classDetails.class.course_id || ''}
              onChange={handleClassDetailsChange}
            />
            <button className="btn-primary" onClick={handleSaveClassDetails}>Save Class Details</button>
          </div>

          <div className="student-list">
            <h3>Students in this Class</h3>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Guardian Name</th>
                  <th>Balance</th>
                  <th>Course Name</th>
                  <th>Change Class</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {classDetails.students.map((student) => (
                  <tr key={student.id}>
                    {selectedStudent?.id === student.id ? (
                      <>
                        <td><input type="text" name="name" value={selectedStudent.name} onChange={(e) => handleStudentChange(e, student)} /></td>
                        <td><input type="text" name="email" value={selectedStudent.email} onChange={(e) => handleStudentChange(e, student)} /></td>
                        <td><input type="text" name="phone_number" value={selectedStudent.phone_number} onChange={(e) => handleStudentChange(e, student)} /></td>
                        <td><input type="text" name="guardian_name" value={selectedStudent.guardian_name} onChange={(e) => handleStudentChange(e, student)} /></td>
                        <td><input type="text" name="balance" value={selectedStudent.balance} onChange={(e) => handleStudentChange(e, student)} /></td>
                        <td><input type="text" name="course_name" value={selectedStudent.course_name} onChange={(e) => handleStudentChange(e, student)} /></td>
                        <td>
                          <select onChange={(e) => handleClassChangeForStudent(student.id, e.target.value)}>
                            <option value="">-- Select class --</option>
                            {classes.map((cls) => (
                              <option key={cls.id} value={cls.id} selected={cls.id === student.class_id}>
                                {cls.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <button onClick={() => handleSaveStudentDetails(student.id)}>Save</button>
                          <button onClick={() => setSelectedStudent(null)}>Cancel</button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{student.name}</td>
                        <td>{student.email}</td>
                        <td>{student.phone_number}</td>
                        <td>{student.guardian_name}</td>
                        <td>{student.balance}</td>
                        <td>{student.course_name}</td>
                        <td>
                          <select onChange={(e) => handleClassChangeForStudent(student.id, e.target.value)}>
                            <option value="">-- Select class --</option>
                            {classes.map((cls) => (
                              <option key={cls.id} value={cls.id} selected={cls.id === student.class_id}>
                                {cls.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <button onClick={() => setSelectedStudent(student)}>Edit</button>
                          <button onClick={() => handleDeleteStudent(student.id)}>Delete</button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="add-student-form">
            <h3>Add New Student</h3>
            <input type="text" name="name" placeholder="Name" value={newStudent.name} onChange={handleNewStudentChange} />
            <input type="password" name="password" placeholder="Password" value={newStudent.password} onChange={handleNewStudentChange} />
            <input type="text" name="role" placeholder="Role" value={newStudent.role} onChange={handleNewStudentChange} />
            <input type="email" name="email" placeholder="Email" value={newStudent.email} onChange={handleNewStudentChange} />
            <input type="text" name="phone_number" placeholder="Phone Number" value={newStudent.phone_number} onChange={handleNewStudentChange} />
            <input type="text" name="guardian_name" placeholder="Guardian Name" value={newStudent.guardian_name} onChange={handleNewStudentChange} />
            <input type="number" name="balance" placeholder="Balance" value={newStudent.balance} onChange={handleNewStudentChange} />
            <input type="text" name="course_name" placeholder="Course Name" value={newStudent.course_name} onChange={handleNewStudentChange} />
            <label htmlFor="class-select">Select Class:</label>
            <select
              id="class-select"
              name="class_id"
              value={newStudent.class_id}
              onChange={handleNewStudentChange}
            >
              <option value="">-- Select Class --</option>
              {classes.map((cls) => (
                <option key={cls.id} value={cls.id}>
                  {cls.name}
                </option>
              ))}
            </select>
            <button className="btn-primary" onClick={handleAddNewStudent}>Add Student</button>
          </div>
        </>
      )}
    </div>
  );
};

export default StudentManagement;
