import React from "react";
import { useNavigate } from "react-router-dom";
import "./Pricing.css";
import { FaCheck, FaStar, FaCertificate, FaProjectDiagram, FaRocket } from "react-icons/fa";

const Pricing = () => {
  const navigate = useNavigate();

  const icons = [
    FaCheck,
    FaStar,
    FaCertificate,
    FaProjectDiagram,
    FaRocket,
  ];

  const pricingData = [
    {
      title: "Python Start",
      price: "390",
      lessons: "16 Ders",
      description: [
        "16 Haftalık temel kursumuz",
        "Ekstra 4 gerçek dünya Projesi",
        "Python Start Sertifikası",
        "Diğer paketlerimizin temelidir",
      ],
    },
    {
      title: "Python +",
      price: "375",
      lessons: "40 Ders",
      description: [
        "40 haftalık gelişmiş kursumuz",
        "Ekstra 10 gerçek dünya Projesi",
        "Python Start ve Yapay Zeka kurslarını içerir",
        "Python Start ve Yapay Zeka Sertifikaları",
      ],
    },
    {
      title: "Python Pro",
      price: "350",
      lessons: "52 Ders",
      description: [
        "40 haftalık ileri seviye kursumuz",
        "Ekstra 10 gerçek dünya Projesi",
        "Python Start, Yapay Zeka ve Prompt mühendisliği kurslarını içerir",
        "Python Start, Yapay Zeka ve Prompt mühendisliği Sertifikaları",
      ],
    },
  ];

  return (
    <div className="mt-8">
      <div className="flex justify-center items-center">
        <p className="font-poppins font-semibold text-3xl text-[#FB8500]">
          Fiyatlandırma
        </p>
      </div>
      <div className="flex justify-center items-center mt-4">
        <p className="text-center text-[#2B2D42] font-poppins font-normal sm:text-3xl">
          
        </p>
      </div>
      <div className="ParentCard flex gap-10 px-10 py-8 mt-8 mx-8 justify-start items-center overflow-x-auto">
        {pricingData.map((data, index) => (
          <div key={index} className={`Cards w-80 h-[600px] px-6 py-4 bg-orange-1 rounded-[20px] flex flex-col justify-between relative`}>
            {index === 1 && (
              <div className="label-popular">
                En Popüler
              </div>
            )}
            {index === 2 && (
              <div className="label-best-price">
                En İyi Fiyat
              </div>
            )}
            <div className="flex flex-col gap-6">
              <div>
                <p className="font-poppins font-bold text-2xl text-[#2B2D42]">
                  {data.title}
                </p>
                <p className="w-[22px] h-[2px] bg-orange-logo mt-1"></p>
              </div>
              <div className="flex flex-col justify-center items-center mt-8">
                <p className="font-poppins font-semibold text-3xl text-[#2B2D42]">
                  {data.price} TL / Ders
                </p>
                <p className="font-poppins font-normal text-xl text-[#747474]">
                  {data.lessons}
                </p>
              </div>
              <div className="flex flex-col gap-4 mt-5">
                <div>
                  <p className="font-poppins font-semibold text-sm text-[#2B2D42]">
                    Sizi neler bekliyor?
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  {data.description.map((desc, i) => {
                    const Icon = icons[i % icons.length];
                    return (
                      <p key={i} className="font-poppins font-normal text-[#2B2D42] text-sm flex items-center mb-2">
                        <Icon className="mr-2 text-green-500" /> {desc}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex rounded-lg mt-10 justify-center items-center">
              <button
                onClick={() => {
                  navigate("/#registration");
                }}
                className="bg-orange-logo text-white font-Sans font-medium rounded-xl w-60 h-11 justify-center items-center px-2 py-2"
              >
                Şimdi Kaydolun
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
