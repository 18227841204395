import React, { useState, useEffect } from 'react';
import './CreateClass.css';  // CSS dosyasını içe aktarıyoruz

const CreateClass = () => {
  const [name, setName] = useState('');
  const [courseId, setCourseId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [lessonTime, setLessonTime] = useState('');
  const [scheduleStartDate, setScheduleStartDate] = useState(''); // Ders programı için başlangıç tarihi

  useEffect(() => {
    fetch('https://ubicraft.org/api/classes')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  const handleCreateClass = () => {
    const newClass = { name, course_id: courseId, start_date: startDate };

    fetch('https://ubicraft.org/api/classes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newClass),
    })
      .then(response => response.json())
      .then(data => {
;
        setClasses([...classes, data]); // Yeni oluşturulan sınıfı listeye ekleyin
      })
      .catch(error => console.error('Error creating class:', error));
  };

  const handleScheduleLessons = () => {
    const scheduleData = { class_time: lessonTime, start_date: scheduleStartDate }; // Başlangıç tarihini ekledik

    fetch(`https://ubicraft.org/api/classes/${selectedClass}/schedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(scheduleData),
    })
      .then(response => response.text())
      .then(text => {


      })
      .catch(error => console.error('Error scheduling lessons:', error));
  };

  const handleDeleteClass = () => {
    if (!selectedClass) return;

    fetch(`https://ubicraft.org/api/classes/${selectedClass}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete class');
        }
        // Sınıfı listeden kaldırın
        setClasses(classes.filter(cls => cls.id !== selectedClass));
        setSelectedClass(''); // Seçimi sıfırlayın
      })
      .catch(error => console.error('Error deleting class:', error));
  };

  return (
    <div className="create-class">
      <h2>Create Class</h2>
      <div className="form-group">
        <label>Class Name</label>
        <input
          type="text"
          placeholder="Class Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Course ID</label>
        <input
          type="number"
          placeholder="Course ID"
          value={courseId}
          onChange={(e) => setCourseId(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Start Date</label>
        <input
          type="date"
          placeholder="Start Date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>
      <button className="btn-primary" onClick={handleCreateClass}>Create Class</button>

      <h2>Schedule Lessons</h2>
      <div className="form-group">
        <label>Select Class</label>
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Lesson Time</label>
        <input
          type="time"
          placeholder="Lesson Time"
          value={lessonTime}
          onChange={(e) => setLessonTime(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Start Date for Scheduling</label> {/* Ders programı için başlangıç tarihi seçeneği */}
        <input
          type="date"
          placeholder="Schedule Start Date"
          value={scheduleStartDate}
          onChange={(e) => setScheduleStartDate(e.target.value)}
        />
      </div>
      <button className="btn-primary" onClick={handleScheduleLessons}>Schedule Lessons</button>

      <h2>Delete Class</h2>
      <div className="form-group">
        <label>Select Class to Delete</label>
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.name}
            </option>
          ))}
        </select>
      </div>
      <button
        className="btn-danger"
        onClick={handleDeleteClass}
        disabled={!selectedClass}
      >
        Delete Selected Class
      </button>
    </div>
  );
};

export default CreateClass;
