import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import Pricingpage from "./pages/Pricing/Pricing";
import Faqs from "./pages/Faqs/faqs";
import Dashboard from "./pages/Dashboard/Dashboard";
import TBoard from "./components/Dashboard/TBoard";
import Classes from "./components/Dashboard/Classes";
import Attendance from "./components/Dashboard/Attendance";
import Notification from "./components/Dashboard/Notification";
import Setting from "./components/Dashboard/setting";
import Topics from "./components/Dashboard/Topics";
import TopicDetail from "./components/Dashboard/TopicDetail";
import Week1 from "./components/Dashboard/Weeks/Week1";
import Week2 from "./components/Dashboard/Weeks/Week2";
import Week3 from "./components/Dashboard/Weeks/Week3";
import Login from "./pages/Login/Login";
import StudentDashboard from "./pages/Student/Dashboard";
import Home from "./components/Student/Home";
import StudentScores from "./components/Student/StudentScores";
import Yoklama from "./components/Student/yoklama";
import AdminPanel from "./components/AdminPanel/AdminPanel";

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error parsing JWT:', error);
    return null;
  }
};

const ProtectedRoute = ({ element, role }) => {
  const token = localStorage.getItem('token');
  const user = token ? parseJwt(token) : null;
  const userId = window.location.pathname.split('/')[2];

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (role && user.role !== role) {
    return <Navigate to="/login" />;
  }

  return element;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/pricing" element={<Pricingpage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/student/:userId" element={<ProtectedRoute element={<StudentDashboard />} role="student" />} >
          <Route index element={<Home />} />
          <Route path="settings" element={<Setting />} />
          <Route path="topics" element={<Topics />} />
          <Route path="week1" element={<Week1 />} />
          <Route path="week2" element={<Week2 />} />
          <Route path="week3" element={<Week3 />} />
          <Route path="notification" element={<Notification />} />
          <Route path="scores" element={<StudentScores />} />
          <Route path="yoklama" element={<Yoklama />} />
        </Route>
        <Route path="/dashboard/:userId/*" element={<ProtectedRoute element={<Dashboard />} role="teacher" />}>
          <Route path="teacher" element={<TBoard />} />
          <Route path="classes" element={<Classes />} />
          <Route path=":classId/attendance" element={<Attendance />} />
          <Route path="notification" element={<Notification />} />
          <Route path="settings" element={<Setting />} />
          <Route path="topics" element={<Topics />} />
          <Route path="topics/:topicId" element={<TopicDetail />} />
          <Route path="week1" element={<Week1 />} />
          <Route path="week2" element={<Week2 />} />
          <Route path="week3" element={<Week3 />} />
        </Route>
        {/* Admin paneli için rota */}
        <Route path="/admin/*" element={<ProtectedRoute element={<AdminPanel />} role="admin" />} />
      </Routes>
    </Router>
  );
};

export default App;
