import React, { useState, useEffect } from 'react';
import './yoklama.css'; // CSS dosyasının doğru yolu
import { useParams } from 'react-router-dom';

const AttendanceCard = ({ Name, studentId }) => {
  const [weeks, setWeeks] = useState(Array(32).fill('grey'));
  const classId = 1; // Örnek bir classId, bunu uygun şekilde ayarlayın

  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await fetch(`https://ubicraft.org/api/attendance/${studentId}/${classId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const newWeeks = [...weeks];
        data.forEach(({ week, status }) => {
          const color = status === 'Katıldı' ? 'green' : status === 'Katılmadı' ? 'red' : 'yellow';
          newWeeks[week - 1] = color;
        });
        setWeeks(newWeeks);
      } catch (error) {
        console.error('Error fetching attendance:', error);
      }
    };

    fetchAttendance();
  }, [studentId, classId]);

  const rows = [];
  for (let i = 0; i < weeks.length; i += 16) {
    rows.push(weeks.slice(i, i + 16));
  }

  return (
    <div className="attendance-card">
      <div className="name-label">
        {Name}
      </div>
      <div className="weeks-container">
        {rows.map((row, rowIndex) => (
          <div key={rowIndex}>
            <div className="module-row font-poppins">
              {Array.from({ length: 4 }, (_, modIndex) => (
                <div className="module-label font-poppins" key={modIndex}>
                  {`M${rowIndex * 4 + modIndex + 1}`}
                </div>
              ))}
            </div>
            <div className="week-row font-poppins">
              {row.map((color, index) => (
                <div className="week2-container font-poppins" key={rowIndex * 16 + index}>
                  <div
                    className={`week-box ${color}`}
                  >
                    <span className="week-number font-poppins">{rowIndex * 16 + index + 1}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Yoklama = () => {
  const { userId } = useParams();

  return (
    <div className="attendance-page">
      <div className="attendance-info">
        <h1>Katılım Bilgisi</h1>
        <div className="info-box">
          <div className="info-item">
            <div className="color-box green"></div>
            <span>Katıldı</span>
          </div>
          <div className="info-item">
            <div className="color-box red"></div>
            <span>Katılmadı</span>
          </div>
          <div className="info-item">
            <div className="color-box yellow"></div>
            <span>İzinli</span>
          </div>
        </div>
      </div>
      <AttendanceCard Name="Öğrencinin İsmi" studentId={userId} />
    </div>
  );
};

export default Yoklama;
