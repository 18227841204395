import React, { useState, useEffect } from "react";
import { IoIosRocket, IoIosLaptop, IoIosCodeWorking, IoIosSchool, IoIosBulb } from "react-icons/io";
import "./Regestration.css"; // CSS dosyasını içeri aktarıyoruz
import MineGif from "../Landing/courseimages/mine.gif";
import MarioGif from "../Landing/courseimages/mario.gif";
import NeonGif from "../Landing/courseimages/neon.gif";

const gifs = [MineGif, MarioGif, NeonGif];

const RegestrationSection = () => {
  const [currentGif, setCurrentGif] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGif((prevGif) => (prevGif + 1) % gifs.length);
    }, 5000); // Her 5 saniyede bir değiştir
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="regestration-container">
      <div className="gif-section">
        <div className="gif-title">Buna benzer bir proje geliştirmek ister misin?</div>
        <img src={gifs[currentGif]} alt="Gif" />
      </div>
      <div className="info-section">
        <div className="info-header">
          <span className="ubicraft">Ubicraft</span> <span className="ile">ile...</span>
        </div>
        <div className="info-icons">
          <div className="icon-text">
            <IoIosRocket className="icon" />
            <p>Hızlı ve etkili öğrenim</p>
          </div>
          <div className="icon-text">
            <IoIosLaptop className="icon" />
            <p>Online ve erişilebilir kurslar</p>
          </div>
          <div className="icon-text">
            <IoIosCodeWorking className="icon" />
            <p>Gerçek dünya projeleri</p>
          </div>
          <div className="icon-text">
            <IoIosSchool className="icon" />
            <p>Deneyimli eğitmenler</p>
          </div>
          <div className="icon-text">
            <IoIosBulb className="icon" />
            <p>Yaratıcı çözümler</p>
          </div>
        </div>
        <p className="platform-info">
          ... ve çok daha fazlası için ücretsiz deneme dersine hemen kaydolun!
        </p>
        <div className="form-submit">
          <a
            href="https://docs.google.com/forms/d/1yP7OWJVAE7Bx8UNW96NdLrSsp9zAOkwdlbFBpbxpADU/edit"
            className="submit-button"
          >
            Deneme dersine katılın
          </a>
        </div>
      </div>
    </div>
  );
};

export default RegestrationSection;
