import React from 'react';
import './AvatarSelection.css';

const AvatarSelection = ({ avatars, onSelect, onClose }) => {
  return (
    <div className="avatar-popup-overlay">
      <div className="avatar-popup">
        <h2>Avatar Seçin</h2>
        <div className="avatar-grid">
          {avatars.map((avatar, index) => (
            <img
              key={index}
              src={avatar}
              alt={`Avatar ${index + 1}`}
              className="avatar-image"
              onClick={() => {
                onSelect(avatar);
                onClose();
              }}
            />
          ))}
        </div>
        <button className="close-button" onClick={onClose}>Kapat</button>
      </div>
    </div>
  );
};

export default AvatarSelection;
