import React, { useState } from 'react';
import CreateClass from './createClass';
import StudentManagement from './StudentManagement';
import CurriculumManagement from './CurriculumManagement';
import TeacherManagement from './TeacherManagement';  // Yeni bileşeni import edin
import './AdminPanel.css';

const AdminPanel = () => {
  const [activeComponent, setActiveComponent] = useState('createClass');
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'createClass':
        return <CreateClass />;
      case 'studentManagement':
        return <StudentManagement />;
      case 'curriculumManagement':
        return (
          <>
            <div className="course-selection">
              <label>Select Course ID:</label>
              <input
                type="number"
                value={selectedCourseId || ''}
                onChange={(e) => setSelectedCourseId(e.target.value)}
              />
            </div>
            {selectedCourseId && <CurriculumManagement selectedCourseId={selectedCourseId} />}
          </>
        );
      case 'teacherManagement':
        return <TeacherManagement />;  // Yeni bileşeni burada render edin
      default:
        return null;
    }
  };

  return (
    <div className="admin-panel">
      <header className="admin-header">
        <h1>Admin Panel</h1>
      </header>
      <nav className="admin-navbar">
        <button onClick={() => setActiveComponent('createClass')}>Create Class</button>
        <button onClick={() => setActiveComponent('studentManagement')}>Student Management</button>
        <button onClick={() => setActiveComponent('curriculumManagement')}>Curriculum Management</button>
        <button onClick={() => setActiveComponent('teacherManagement')}>Teacher Management</button> {/* Yeni buton */}
      </nav>
      <main className="admin-content">
        {renderComponent()}
      </main>
    </div>
  );
};

export default AdminPanel;
