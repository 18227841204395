import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight, FaCertificate, FaUserGraduate, FaTrophy, FaEnvelope } from "react-icons/fa";
import CertificateImage1 from "./sertifika.png";
import CertificateImage2 from "./sertifika2.png";
import CertificateImage3 from "./sertifika3.png";
import "./Company.css"; // CSS dosyasını import ediyoruz

const Company = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isAutoplay, setIsAutoplay] = useState(true);

  const certificates = [CertificateImage1, CertificateImage2, CertificateImage3];

  useEffect(() => {
    let interval;
    if (isAutoplay) {
      interval = setInterval(() => {
        handleNext();
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [currentIndex, isAutoplay]);

  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setIsAutoplay(false);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? certificates.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setIsAutoplay(false);
    setCurrentIndex((prevIndex) => (prevIndex === certificates.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="certificate-section bg-fce0a2 p-10">
      <div className="flex flex-col md:flex-row items-center mt-10">
        <div className="unique-certificate-slider md:w-1/2">
          <button onClick={handlePrev} className="unique-certificate-button left">
            <FaChevronLeft />
          </button>
          <div className="overflow-hidden w-full max-w-3xl h-auto">
            <img
              src={certificates[currentIndex]}
              alt={`Sertifika ${currentIndex + 1}`}
              className={`transition-transform duration-500 ease-in-out transform ${isAnimating ? 'scale-105' : 'scale-100'}`}
              onLoad={() => setIsAnimating(false)}
            />
          </div>
          <button onClick={handleNext} className="unique-certificate-button right">
            <FaChevronRight />
          </button>
        </div>
        <div className="md:w-1/2 md:pl-10 mt-10 md:mt-0">
          <h2 className="font-poppins font-semibold text-3xl mb-4 text-orange-500 sertifika">Sertifikalandırma</h2>
          <p className="font-poppins text-xl mb-4">
            Eğitimlerimiz ile kendinizi geliştirin ve kariyerinizde bir adım öne geçin. Her bir sertifika, başarıya ulaşan yolda önünüzü açar.
          </p>
          <ul className="list-none font-poppins">
            <li className="flex items-center mb-4">
              <FaCertificate className="text-orange-500 mr-2 icon-size" />
              Sertifikalarımız, öğrencimizin sahip olduğu bilgi ve becerilerin bir kanıtıdır.
            </li>
            <li className="flex items-center mb-4">
              <FaUserGraduate className="text-orange-500 mr-2 icon-size" />
              Erken yaşta alınan sertifikalar, çocuğunuzun profesyonel iş yaşantısına adapte olmasını kolaylaştırır.
            </li>
            <li className="flex items-center mb-4">
              <FaTrophy className="text-orange-500 mr-2 icon-size" />
              Öğrencimiz sertifikayı ancak beceri sınavını başarıyla geçerse alabilir. Bu da sertifikanızın değerini artırır.
            </li>
            <li className="flex items-center mb-4">
              <FaEnvelope className="text-orange-500 mr-2 icon-size" />
              Sertifikanız kurumumuz tarafından onaylandığı takdirde hem dijital olarak hem de posta yoluyla size ulaştırılır.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Company;
