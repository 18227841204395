import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./TopicDetail.css";
import DragDropTopic from "./DragDropTopic";

const TopicDetail = () => {
  const { topicId } = useParams();

  const topicDetails = {
    1: {
      name: 'Değişkenler ve Veri Tipleri',
      content: (
        <div>
          <h3 style={{ color: 'orange' }}>Değişken Nedir?</h3>
          <ul>
            <li>
              Değişken, bir programlama dilinde verileri saklamak için kullanılan isimlendirilmiş bir alandır.
            </li>
            <li>
              Değişkenler, veriyi bellek alanında saklar ve programın ilerleyen aşamalarında bu veriye erişmemizi sağlar.
            </li>
            <li>
              Değişkenler, veriyi saklamak ve gerektiğinde kullanmak için önemlidir.
            </li>
          </ul>
          
          <h3 style={{ color: 'orange' }}>Python'da Değişken Tanımlama</h3>
          <ul>
            <li>
              Python'da bir değişken tanımlamak oldukça basittir. Bir değişkene değer atamak için <code>=</code> operatörünü kullanırız.
            </li>
            <li>Değişken isimlendirmesinde bazı kurallara dikkat edilmelidir:</li>
            <ul>
              <li>Değişken isimleri harf veya alt çizgi (_) ile başlamalıdır.</li>
              <li>Değişken isimleri rakamla başlayamaz.</li>
              <li>Değişken isimleri büyük/küçük harfe duyarlıdır (örneğin, <code>isim</code> ve <code>Isim</code> farklı değişkenlerdir).</li>
              <li>Değişken isimleri boşluk içeremez, bunun yerine alt çizgi kullanılabilir (örneğin, <code>ogrenci_adi</code>).</li>
            </ul>
          </ul>
          
          <h3 style={{ color: 'orange' }}>Örnekler</h3>
          
          <h4 style={{ color: 'orange' }}>String (Metin) Değişkeni</h4>
          <pre className="code-block">
            <code>
              {`isim = "Ali"\nsehir = 'İstanbul'`}
            </code>
          </pre>
          
          <h4 style={{ color: 'orange' }}>Integer (Tam Sayı) Değişkeni</h4>
          <pre className="code-block">
            <code>
              {`yas = 14\nsinif = 8`}
            </code>
          </pre>
          
          <h4 style={{ color: 'orange' }}>Float (Ondalık Sayı) Değişkeni</h4>
          <pre className="code-block">
            <code>
              {`ortalama = 85.5\npi = 3.14`}
            </code>
          </pre>
          
          <h4 style={{ color: 'orange' }}>Boolean (Mantıksal) Değişkeni</h4>
          <pre className="code-block">
            <code>
              {`dogru_mu = True\nyanlis_mi = False`}
            </code>
          </pre>
          
          <h3 style={{ color: 'orange' }}>Değişkenleri Kullanma</h3>
          <ul>
            <li>
              Bir değişken tanımlandıktan sonra, bu değişkenin değerini kullanarak çeşitli işlemler yapabiliriz.
            </li>
            <li>
              Değişkenlerin değerlerini ekrana yazdırmak için <code>print()</code> fonksiyonunu kullanabiliriz.
            </li>
          </ul>
          
          <h4 style={{ color: 'orange' }}>Örnekler</h4>
          <pre className="code-block">
            <code>
              {`isim = "Ali"\nyas = 14\nortalama = 85.5\n\nprint("İsim:", isim)\nprint("Yaş:", yas)\nprint("Ortalama:", ortalama)`}
            </code>
          </pre>
          
          <h3 style={{ color: 'orange' }}>Değişkenlerin Değerini Değiştirme</h3>
          <ul>
            <li>
              Bir değişkenin değerini istediğimiz zaman değiştirebiliriz. Bunun için tekrar <code>=</code> operatörünü kullanarak yeni bir değer atayabiliriz.
            </li>
          </ul>
          
          <h4 style={{ color: 'orange' }}>Örnek</h4>
          <pre className="code-block">
            <code>
              {`yas = 14\nprint("Şu anki yaş:", yas)\n\nyas = 15  # Yaşı bir yıl artırıyoruz\nprint("Gelecek yılki yaş:", yas)`}
            </code>
          </pre>
          
          <h3 style={{ color: 'orange' }}>Sonuç</h3>
          <ul>
            <li>
              Değişkenler, programlamanın temel taşlarından biridir. Veriyi saklamak, üzerinde işlem yapmak ve gerektiğinde bu veriye erişmek için değişkenleri kullanırız.
            </li>
            <li>
              Python'da değişken tanımlama ve kullanma oldukça basit ve esnektir.
            </li>
            <li>
              Değişkenlerin temelini öğrendikten sonra, daha karmaşık programlar yazmak için bu bilgiyi kullanabilirsiniz.
            </li>
          </ul>
        </div>
      ),
      question: 'Python\'da bir değişken tanımlamak için hangi operatör kullanılır?',
      options: [
        '+',
        '-',
        '=',
        '*'
      ],
      correctAnswer: 2
    },
    2: {
      name: 'Derin Öğrenme',
      content: 'Derin Öğrenme, yapay sinir ağları kullanarak verilerden öğrenmeyi amaçlayan bir makine öğrenmesi tekniğidir. Özellikle büyük veri setleri ve karmaşık modeller için etkilidir.',
      question: 'Derin Öğrenme nedir?',
      options: [
        'Bir tür web geliştirme tekniğidir',
        'Yapay sinir ağları kullanarak verilerden öğrenmeyi amaçlayan bir makine öğrenmesi tekniğidir',
        'Bir tür veri tabanı yönetim sistemidir',
        'Bir tür yazılım geliştirme yöntemidir'
      ],
      correctAnswer: 1
    },
    3: {
      name: 'Makine Öğrenimi ve Derin Öğrenme Farkı',
      content: 'Makine öğrenimi ve derin öğrenme arasındaki farklar...',
      type: 'drag-drop',
      items: [
        { id: 1, text: 'Makine öğrenimi, verilerden öğrenmeyi sağlar.', category: 'ml' },
        { id: 2, text: 'Derin öğrenme, yapay sinir ağlarını kullanır.', category: 'dl' },
        { id: 3, text: 'Makine öğrenimi, genellikle az veri gerektirir.', category: 'ml' },
        { id: 4, text: 'Derin öğrenme, büyük veri setlerine ihtiyaç duyar.', category: 'dl' },
        { id: 5, text: 'Makine öğrenimi, basit modeller kullanır.', category: 'ml' },
        { id: 6, text: 'Derin öğrenme, karmaşık modeller kullanır.', category: 'dl' }
      ]
    }
  };

  const topic = topicDetails[topicId];
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (!topic) {
    return <div>Konu bulunamadı.</div>;
  }

  const handleOptionChange = (index) => {
    setSelectedOption(index);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  return (
    <div className="topic-detail-container">
      <h2>{topic.name}</h2>
      <div>{topic.content}</div>
      {topic.type === 'drag-drop' ? (
        <DragDropTopic items={topic.items} />
      ) : (
        <div className="question-section">
          <h3>{topic.question}</h3>
          <div className="options">
            {topic.options.map((option, index) => (
              <div key={index} className="option">
                <input
                  type="radio"
                  id={`option-${index}`}
                  name="option"
                  value={index}
                  onChange={() => handleOptionChange(index)}
                  disabled={isSubmitted}
                />
                <label htmlFor={`option-${index}`}>{option}</label>
              </div>
            ))}
          </div>
          {!isSubmitted ? (
            <button className="submit-button" onClick={handleSubmit} disabled={selectedOption === null}>
              Gönder
            </button>
          ) : (
            <div className={`result ${selectedOption === topic.correctAnswer ? 'correct' : 'incorrect'}`}>
              {selectedOption === topic.correctAnswer ? 'Doğru!' : 'Yanlış. Doğru cevap: ' + topic.options[topic.correctAnswer]}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TopicDetail;
