import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./StudentScores.css";

const StudentScores = () => {
  const { userId } = useParams();
  const [scores, setScores] = useState([]);

  useEffect(() => {
    fetchScores();
  }, []);

  const fetchScores = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://ubicraft.org/api/student/${userId}/scores`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setScores(data);
        updateProgressCircles(data);
      } else {
        console.error('Failed to fetch scores:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching scores:', error);
    }
  };

  const calculatePercentage = (score) => {
    return (score / 200) * 100;
  };

  const getProgressColor = (score) => {
    if (score <= 40) return '#ff4d4f'; // kırmızı
    if (score <= 80) return '#fa8c16'; // turuncu
    if (score <= 120) return '#faad14'; // sarı
    if (score <= 160) return '#52c41a'; // yeşil
    return '#1890ff'; // mavi
  };

  const updateProgressCircles = (scores) => {
    scores.forEach((score, index) => {
      const percentage = calculatePercentage(score.score);
      const circle = document.querySelector(`.progress-circle[data-index="${index}"]`);
      if (circle) {
        circle.style.setProperty('--percentage', `${percentage}%`);
        circle.style.setProperty('--progress-color', getProgressColor(score.score));
      }
    });
  };

  return (
    <div className="scores-container">
      <h2 className="scores-title">Puanlama</h2>
      <div className="scores-list">
        {scores.map((score, index) => (
          <div key={index} className="score-item">
            <span className="week-label">Hafta {score.week}</span>
            <div className="progress-circle" data-index={index} data-percentage={calculatePercentage(score.score)}>
              <span className="progress-circle-value">{score.score}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentScores;
