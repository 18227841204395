import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./TrackCard.css";
import Proje from "../../Icons/Proje.png";
import Kurs from "../../Icons/Kurs.png";
import Ogrenci from "../../Icons/Ogrenci.png";
import Python from "../../Icons/Python.png";
import Dersler from "../../Icons/Dersler.png";

const TrackCard = () => {
  const { userId } = useParams();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://ubicraft.org/api/student/${userId}/courses`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setCourses(data);
        } else {
          console.error('Failed to fetch courses:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [userId]);

  return (
    <div className="track-cards">
      {courses.map((course) => (
        <div className="track-card" key={course.id}>
          <div className="track-header">
            <span className="track-title">{course.course_name.toUpperCase()}</span>
            <h1>{course.course_name}</h1>
            <button className="continue-button">Kursa Devam Et</button>
          </div>
          <div className="track-info">
            <div className="track-meta">
              <div className="track-meta-item">
                <img src={Python} alt="Python" />
                <span>Python</span>
              </div>
              <div className="track-meta-item">
                <img src={Dersler} alt="Dersler" />
                <span>{course.lessons_count} Ders</span>
              </div>
              <div className="track-meta-item">
                <img src={Proje} alt="Proje" />
                <span>{course.projects_count} Proje</span>
              </div>
              <div className="track-meta-item">
                <img src={Kurs} alt="Kurs" />
                <span>{course.advanced_courses}</span>
              </div>
              <div className="track-meta-item">
                <img src={Ogrenci} alt="Öğrenci" />
                <span>{course.students_count} Öğrenci</span>
              </div>
            </div>
          </div>
          <div className="track-footer">
            <div className="track-progress">
              <span>{course.description}</span>
              <div className="progress-bar">
                <div className="progress" style={{ width: `${course.progress}%` }}></div>
              </div>
            </div>
            <div className="bonus-material">
              <span>Bonus Proje {course.bonus_projects}/4</span>
              <div className="bonus-icons">
                {[...Array(4)].map((_, i) => (
                  <div key={i} className="bonus-icon">🌟</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrackCard;