import React, { useState } from "react";
import "./DragDropTopic.css";

const DragDropTopic = ({ items }) => {
  const [mlCards, setMlCards] = useState([]);
  const [dlCards, setDlCards] = useState([]);
  const [cards, setCards] = useState(items);

  const onDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
  };

  const onDrop = (e, category) => {
    e.preventDefault();
    const id = e.dataTransfer.getData("id");

    const draggedCard = cards.find((card) => card.id == id);
    const updatedCards = cards.filter((card) => card.id != id);

    if (category === "ml") {
      setMlCards((prev) => [...prev, draggedCard]);
    } else if (category === "dl") {
      setDlCards((prev) => [...prev, draggedCard]);
    }

    setCards(updatedCards);
  };

  return (
    <div className="drag-drop-container">
      <div className="cards-container">
        {cards.map((card) => (
          <div
            key={card.id}
            className="card"
            draggable
            onDragStart={(e) => onDragStart(e, card.id)}
          >
            {card.text}
          </div>
        ))}
      </div>
      <div className="slots-container">
        <div>
          <div className="slot-title">Machine Learning</div>
          <div
            className="slot"
            onDrop={(e) => onDrop(e, "ml")}
            onDragOver={(e) => e.preventDefault()}
          >
            {mlCards.map((card) => (
              <div key={card.id} className="card">
                {card.text}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="slot-title">Deep Learning</div>
          <div
            className="slot"
            onDrop={(e) => onDrop(e, "dl")}
            onDragOver={(e) => e.preventDefault()}
          >
            {dlCards.map((card) => (
              <div key={card.id} className="card">
                {card.text}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DragDropTopic;
