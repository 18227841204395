import React, { useState, useEffect } from 'react';
import './AttendanceCard.css'; // CSS dosyasının doğru yolu

const AttendanceCard = ({ Name, studentId }) => {
  const [weeks, setWeeks] = useState(Array(32).fill('grey'));
  const [selectedIndex, setSelectedIndex] = useState(null);
  const classId = 1; // Örnek bir classId, bunu uygun şekilde ayarlayın

  // İşaretlemeleri yüklemek için useEffect
  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await fetch(`https://ubicraft.org/api/attendance/${studentId}/${classId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const newWeeks = [...weeks];
        data.forEach(({ week, status }) => {
          const color = status === 'Katıldı' ? 'green' : status === 'Katılmadı' ? 'red' : 'yellow';
          newWeeks[week - 1] = color;
        });
        setWeeks(newWeeks);
      } catch (error) {
        console.error('Error fetching attendance:', error);
      }
    };

    fetchAttendance();
  }, [studentId, classId]);

  const handleColorChange = async (index, color) => {
    const newWeeks = [...weeks];
    newWeeks[index] = color;
    setWeeks(newWeeks);
    setSelectedIndex(null);

    try {
      const response = await fetch('https://ubicraft.org/api/attendance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          studentId: studentId,
          classId: classId,
          week: index + 1,
          status: color === 'green' ? 'Katıldı' : color === 'red' ? 'Katılmadı' : 'İzinli'
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const result = await response.json();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleBoxClick = (index) => {
    setSelectedIndex(selectedIndex === index ? null : index);
  };

  const rows = [];
  for (let i = 0; i < weeks.length; i += 16) {
    rows.push(weeks.slice(i, i + 16));
  }

  return (
    <div className="attendance-card">
      <div className="name-label">
        {Name}
      </div>
      <div className="weeks-container">
        {rows.map((row, rowIndex) => (
          <div key={rowIndex}>
            <div className="module-row font-poppins">
              {Array.from({ length: 4 }, (_, modIndex) => (
                <div className="module-label font-poppins" key={modIndex}>
                  {`M${rowIndex * 4 + modIndex + 1}`}
                </div>
              ))}
            </div>
            <div className="week-row font-poppins">
              {row.map((color, index) => (
                <div className="week2-container font-poppins" key={rowIndex * 16 + index}>
                  <div
                    className={`week-box ${color}`}
                    onClick={() => handleBoxClick(rowIndex * 16 + index)}
                  >
                    <span className="week-number font-poppins">{rowIndex * 16 + index + 1}</span>
                  </div>
                  {selectedIndex === rowIndex * 16 + index && (
                    <div className="color-options font-poppins">
                      <button onClick={() => handleColorChange(rowIndex * 16 + index, 'green')}>Katıldı</button>
                      <button onClick={() => handleColorChange(rowIndex * 16 + index, 'red')}>Katılmadı</button>
                      <button onClick={() => handleColorChange(rowIndex * 16 + index, 'yellow')}>İzinli</button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttendanceCard;
