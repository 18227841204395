import React, { useState, useEffect } from "react";
import "./Creativity.css";
import { FaCode, FaLightbulb, FaRobot } from "react-icons/fa"; // Importing react-icons

const Creativity = () => {
  const [activeCard, setActiveCard] = useState(0);
  const cards = [
    {
      image: "/assets/1.jpg",
      icon: <FaCode size={50} color="#fb8500" />,
      title: "Kodlama Macerası",
      description: "Çocuklar için tasarlanmış, interaktif bir platform olan Ubicraft kodlama becerilerini öğrenme ve yaratıcılığı geliştirme imkanı sunuyor."
    },
    {
      image: "/assets/2.jpg",
      icon: <FaLightbulb size={50} color="#fff" />,
      title: "Hayal Gücü",
      description: "Sadece ders anında değil her zaman yanınızdayız! 7/24 erişiminize açık olan Ubicraft platform ile çocuklar diledikleri an hayal güçlerini gerçek projelere dönüştürebilirler."
    },
    {
      image: "/assets/3.jpg",
      icon: <FaRobot size={50} color="#fff" />,
      title: "Yapay Zeka",
      description: "Her çocuk özeldir. Öğretmen geri bildirimlerinin yanı sıra yapay zeka destekli Ubicraft platform sayesinde öğrenciye özel çözümler üretiyoruz."
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCard((prevActiveCard) => (prevActiveCard + 1) % cards.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [cards.length]);

  return (
    <div className="creativity-container-custom">
      <div className="header-custom">
        <h2 className="section-title-custom">Neden <span className="ubicraft-highlight-custom">Ubicraft?</span></h2>
        <p className="section-subtitle-custom">Çocuklar için tasarlanmış, interaktif bir platform olan Ubicraft kodlama becerilerini öğrenme ve yaratıcılığı geliştirme imkanı sunuyor. Geleceğin liderlerini şimdiden şekillendirmeye başlayın!</p>
      </div>
      <div className="creativity-cards-container-custom">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`creativity-card-custom ${index === activeCard ? 'active' : ''}`}
          >
            <div className="image-section-custom">
              <img className="card-image-custom" src={card.image} alt={card.title} />
            </div>
            <div className="icon-custom">
              {card.icon}
            </div>
            <h3 className="card-title-custom">{card.title}</h3>
            <p className="card-description-custom">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Creativity;
