import React from "react";
import './Experience.css';

const Experience = () => {
  return (
    <div className="experience-bg-white experience-justify-center experience-items-center experience-mt-14 experience-flex experience-flex-col experience-gap-4">
      <div className="experience-flex experience-bg-white experience-rounded-lg experience-mt-4">
        <p className="experience-text-center experience-text-primary experience-font-poppins experience-font-medium lg:experience-text-xl exp">
          <span className="experience-text-accent"> Ücretsiz deneme dersimizle </span>  uzman bir öğretmenimiz eşliğinde<br className="hidden lg:block" />
           <span className="experience-text-accent"> Ubicraft </span> platformu deneyimleyin ve sadece bir oturumda <br className="hidden lg:block" />
          çocuğunuzun<span className="experience-text-accent"> sınırsız </span> potansiyelini ortaya çıkarın
        </p>
      </div>
      <div className="experience-flex boxes3 experience-gap-3 experience-justify-center experience-rounded-lg experience-mt-14">
        <div className="experience-flex experience-flex-1 experience-flex-col experience-gap-6 experience-bg-orange-logo experience-px-8 experience-py-12 experience-rounded-xl">
          <div className="experience-flex experience-justify-center experience-items-center experience-text-white self-center experience-text-5xl experience-font-bold">
            1
          </div>
          <div>
            <p className="experience-text-center experience-font-poppins experience-text-white experience-font-semibold experience-text-lg">
              Sizi neler bekliyor?
            </p>
          </div>
          <div>
            <p className="experience-text-center experience-font-poppins experience-text-white experience-font-normal experience-text-base">
            <br />Dilediğiniz kursun ilk dersini birebir<br />   deneyimleyin. Yeni nesil öğretim <br /> platformumuzu keşfedin. Dersin  <br /> sonunda öğretmenimiz ile birlikte   
            </p>
          </div>
        </div>

        <div className="experience-flex experience-flex-1 experience-flex-col experience-gap-6 experience-bg-orange-logo experience-px-8 experience-py-12 experience-rounded-xl">
          <div className="experience-flex experience-justify-center experience-items-center experience-text-white self-center experience-text-5xl experience-font-bold">
            2
          </div>
          <div>
            <p className="experience-text-center experience-font-poppins experience-text-white experience-font-semibold experience-text-lg">
              Ders İçeriği
            </p>
          </div>
          <div>
            <p className="experience-text-center experience-font-poppins experience-text-white experience-font-normal experience-text-base">
            <br />1 saatlik ders süresinde, öğrencinin <br />  ilk haftanın etkinliklerini öğretmen <br /> eşliğinde tamamlaması amaçlanır <br /> ve öğrencinin seviyesi tespit <br /> öğrencinin seviyesi tespit edilir.<br />
            </p>
          </div>
        </div>

        <div className="experience-flex experience-flex-1 experience-flex-col experience-gap-6 experience-bg-orange-logo experience-px-8 experience-py-12 experience-rounded-xl">
          <div className="experience-flex experience-justify-center experience-items-center experience-text-white self-center experience-text-5xl experience-font-bold">
            3
          </div>
          <div>
            <p className="experience-text-center experience-font-poppins experience-text-white experience-font-semibold experience-text-lg">
              Deneme Dersinin Faydaları 
            </p>
          </div>
          <div>
            <p className="experience-text-center experience-font-poppins experience-text-white experience-font-normal experience-text-base">
            <br />Öğrencimizin kursa adaptasyon <br />  sürecini hızlandırın. Gerçek kurs <br /> deneyiminin ilk dersine hazır hale<br />   gelin. Aklınızdaki soru işaretlerini <br />  ortadan kaldırın.<br /> 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
