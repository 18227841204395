import React, { useState, useEffect } from 'react';
import './CurriculumManagement.css';

const CurriculumManagement = ({ selectedCourseId }) => {
  const [lessons, setLessons] = useState([]);
  const [newLesson, setNewLesson] = useState({
    week_number: '',
    lesson_name: '',
    details: '',
    is_project_week: false,
  });
  const [editingLesson, setEditingLesson] = useState(null);

  useEffect(() => {
    if (selectedCourseId) {
      fetch(`https://ubicraft.org/api/courses/${selectedCourseId}/lessons`)
        .then(response => response.json())
        .then(data => setLessons(data))
        .catch(error => console.error('Error fetching lessons:', error));
    }
  }, [selectedCourseId]);

  const handleInputChange = (e, isEditing = false) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;

    if (isEditing) {
      setEditingLesson({
        ...editingLesson,
        [name]: updatedValue,
      });
    } else {
      setNewLesson({
        ...newLesson,
        [name]: updatedValue,
      });
    }
  };

  const handleSaveNewLesson = () => {
    fetch(`https://ubicraft.org/api/courses/${selectedCourseId}/lessons`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newLesson),
    })
      .then(response => response.json())
      .then(data => setLessons([...lessons, data]))
      .catch(error => console.error('Error creating lesson:', error));
  };

  const handleSaveEditedLesson = (lessonId) => {
    fetch(`https://ubicraft.org/api/lessons/${lessonId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editingLesson),
    })
      .then(response => response.json())
      .then(updatedLesson => {
        setLessons(lessons.map(lesson => (lesson.id === lessonId ? updatedLesson : lesson)));
        setEditingLesson(null);
      })
      .catch(error => console.error('Error updating lesson:', error));
  };

  const handleDeleteLesson = (lessonId) => {
    fetch(`https://ubicraft.org/api/lessons/${lessonId}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (response.ok) {
          setLessons(lessons.filter(lesson => lesson.id !== lessonId));
        }
      })
      .catch(error => console.error('Error deleting lesson:', error));
  };

  return (
    <div className="curriculum-management">
      <h2>Curriculum Management</h2>
      <div className="new-lesson-form">
        <h3>Add New Lesson</h3>
        <input
          type="number"
          name="week_number"
          placeholder="Week Number"
          value={newLesson.week_number}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="lesson_name"
          placeholder="Lesson Name"
          value={newLesson.lesson_name}
          onChange={handleInputChange}
        />
        <textarea
          name="details"
          placeholder="Lesson Details"
          value={newLesson.details}
          onChange={handleInputChange}
        />
        <label>
          <input
            type="checkbox"
            name="is_project_week"
            checked={newLesson.is_project_week}
            onChange={handleInputChange}
          />
          Is Project Week
        </label>
        <button onClick={handleSaveNewLesson}>Add Lesson</button>
      </div>

      <div className="lessons-list">
        <h3>Lessons</h3>
        {lessons.map(lesson => (
          <div key={lesson.id} className="lesson-item">
            {editingLesson?.id === lesson.id ? (
              <>
                <input
                  type="number"
                  name="week_number"
                  value={editingLesson.week_number}
                  onChange={(e) => handleInputChange(e, true)}
                />
                <input
                  type="text"
                  name="lesson_name"
                  value={editingLesson.lesson_name}
                  onChange={(e) => handleInputChange(e, true)}
                />
                <textarea
                  name="details"
                  value={editingLesson.details}
                  onChange={(e) => handleInputChange(e, true)}
                />
                <label>
                  <input
                    type="checkbox"
                    name="is_project_week"
                    checked={editingLesson.is_project_week}
                    onChange={(e) => handleInputChange(e, true)}
                  />
                  Is Project Week
                </label>
                <button onClick={() => handleSaveEditedLesson(lesson.id)}>Save</button>
                <button onClick={() => setEditingLesson(null)}>Cancel</button>
              </>
            ) : (
              <>
                <div className="lesson-details">
                  <p><strong>Week {lesson.week_number}:</strong> {lesson.lesson_name}</p>
                  <p>{lesson.details}</p>
                  <p><strong>Project Week:</strong> {lesson.is_project_week ? 'Yes' : 'No'}</p>
                </div>
                <div className="lesson-actions">
                  <button onClick={() => setEditingLesson(lesson)}>Edit</button>
                  <button onClick={() => handleDeleteLesson(lesson.id)}>Delete</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurriculumManagement;
