import React, { useState } from "react";
import "./EducationPlatform.css";
import studentImage from './ogrenci.png'; // Assuming the image is in the same directory
import teacherImage from './ogretmen.png'; // Assuming the image is in the same directory

const EducationPlatformComponent = () => {
  const [selected, setSelected] = useState("teacher");

  const teacherTags = [
    { title: "Öğrenci Yönetimi", description: "Öğrenci takibini olabildiğince kolaylaştırır", img: "/assets/t1.png", className: "teacher-tag-1" },
    { title: "Özelleştirilmiş Ödevler", description: "Her öğrenciye özel ödevler tasarlanır", img: "/assets/t2.svg", className: "teacher-tag-2" },
    { title: "Notlandırma", description: "Öğrencinin ders içi aktivitesi ve ödevleri puanlanır", img: "/assets/t3.svg", className: "teacher-tag-3" },
    { title: "Yoklama ve Bildirimler", description: "Öğrenci eğer derse katılmazsa, öğretmen anında aksiyon alır", img: "/assets/t4.svg", className: "teacher-tag-4" },
  ];

  const studentTags = [
    { title: "Güçlü Müfredat", description: "Her kurs öğrencinin bir yazılımcı olmak için tüm gereksinimleri gözetilerek hazırlanmıştır", img: "/assets/s1.svg", className: "student-tag-1" },
    { title: "Ödevlendirme", description: "Öğrencinin ödevleri her hafta kontrol edilir ve geri bildirim verilir", img: "/assets/s2.svg", className: "student-tag-2" },
    { title: "Gelişim Takibi", description: "Öğrencinin gelişiminden her zaman haberdar olun", img: "/assets/s3.svg", className: "student-tag-3" },
    { title: "Online Python Programına Erişim", description: "Öğrencilere kodlarını yazabilmeleri için online bir Python programına erişim izni sağlanır", img: "/assets/s4.svg", className: "student-tag-4" },
  ];

  const tags = selected === "teacher" ? teacherTags : studentTags;
  const image = selected === "teacher" ? teacherImage : studentImage;

  return (
    <div className="flex flex-col items-center bg-light-orange padding-small md:padding-large">
      <div className="font-poppins text-dark-blue font-semibold text-2xl text-center" style={{ marginTop: 50 }}>
        Öğrenciler ve öğretmenler için özel çözümler
      </div>
      <div className="platform-container">
        <div className="platform-image-container">
          <img src={image} alt="Platform" className="platform-image" />
          <div className="platform-buttons">
            <div
              onClick={() => setSelected("teacher")}
              className={`teacher-platform-btn text-xl font-semibold margin-bottom-small font-poppins text-black-1 ${
                selected === "teacher" ? "selected" : ""
              } padding-horizontal-medium padding-vertical-small rounded-full cursor-pointer`}
            >
              Öğretmen Platformu
            </div>
            <div
              onClick={() => setSelected("student")}
              className={`student-platform-btn text-xl font-semibold margin-bottom-small font-poppins text-black-1 ${
                selected === "student" ? "selected" : ""
              } cursor-pointer padding-horizontal-medium rounded-full padding-vertical-small`}
            >
              Öğrenci Platformu
            </div>
          </div>
        </div>
        <div className="platform-tags">
          {tags.map((tag, index) => (
            <div key={index} className={`tag ${tag.className}`}>
              <img src={tag.img} alt={tag.title} className="tag-img" />
              <div>
                <h3 className="tag-title">{tag.title}</h3>
                <p className="tag-description">{tag.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EducationPlatformComponent;
