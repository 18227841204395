import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/tr"; // Türkçe dil desteğini ekleyin
import { useParams } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./TBoard.css";

const localizer = momentLocalizer(moment);

const TBoard = () => {
  const { userId } = useParams();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    moment.locale("tr"); // moment'ı Türkçe olarak ayarlayın

    const token = localStorage.getItem('token');

    fetch(`https://ubicraft.org/api/schedules/teacher/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const formattedEvents = data.map(event => {
          const start = new Date(event.lesson_date);
          start.setHours(parseInt(event.lesson_time.split(':')[0], 10));
          start.setMinutes(parseInt(event.lesson_time.split(':')[1], 10));

          const end = new Date(start);
          end.setHours(start.getHours() + 1); // Ders süresi varsayılan olarak 1 saat

          return {
            title: event.lesson_name,
            start: start,
            end: end,
            allDay: false,
          };
        });

        setEvents(formattedEvents);
      })
      .catch(error => {
        console.error('Fetch error:', error.message);
      });
  }, [userId]);

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['month']}  // Sadece aylık görünümü gösterin
        style={{ height: 600 }}  // Takvim boyutunu ayarlayın
        className="custom-calendar"  // Özelleştirilmiş className ekleyin
        eventPropGetter={(event) => ({
          style: {
            borderRadius: '5px',
            border: 'none',
          },
        })}
      />
    </div>
  );
};

export default TBoard;
