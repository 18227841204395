import { useState } from "react";
import { Outlet } from "react-router-dom";
import MenuBarMobile from "../../components/Dashboard/MenuBarMobile";
import Sidebar from "../../components/Student/SideBar";

function StudentDashboard() {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="min-h-screen flex" >
      <MenuBarMobile setter={setShowSidebar} />
      <Sidebar show={showSidebar} setter={setShowSidebar} />
      <div className={`main-content flex-grow ${showSidebar ? "sidebar-open" : ""}`}style ={{marginTop:100}}>
        <Outlet />
      </div>
    </div>
  );
}

export default StudentDashboard;
